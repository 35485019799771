import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Notifications from '@kyvg/vue3-notification'
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { AuthenticationResult, EventType, InteractionRequiredAuthError, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient";
import './index.css'

// console.log('ENV', process.env, msalInstance)
// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
// console.log('accounts', accounts)
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  // console.log('msalInstance.addEventCallback', event);
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    // console.log('msalInstance.addEventCallback logged in')
  }
});

const app = createApp(App);

app.use(router);
app.use(Notifications)
app.use(msalPlugin, msalInstance);
router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount('#app');
});
