import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-darkgreyblue text-white p-2 mb-4 flex" }
const _hoisted_2 = { class: "mr-3" }
const _hoisted_3 = { class: "flex-auto" }
const _hoisted_4 = {
  key: 0,
  class: "title"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_SvgIcon = _resolveComponent("SvgIcon")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar),
    _createVNode(_component_router_view),
    _createVNode(_component_notifications, {
      position: "top center",
      classes: "",
      width: "500"
    }, {
      body: _withCtx((props) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (props.item.type=='error')
              ? (_openBlock(), _createBlock(_component_SvgIcon, {
                  key: 0,
                  name: "error"
                }))
              : _createCommentVNode("", true),
            (props.item.type=='warn')
              ? (_openBlock(), _createBlock(_component_SvgIcon, {
                  key: 1,
                  name: "warning"
                }))
              : _createCommentVNode("", true),
            (props.item.type=='success')
              ? (_openBlock(), _createBlock(_component_SvgIcon, {
                  key: 2,
                  name: "success"
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (props.item.title)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(props.item.title), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              innerHTML: props.item.text
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_SvgIcon, {
              name: "close",
              onClick: props.close,
              class: "cursor-pointer"
            }, null, 8, ["onClick"])
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}