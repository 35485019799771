
import { defineComponent } from 'vue';
import { useIsAuthenticated } from '../composition-api/useIsAuthenticated';
import SignInButton from "./SignInButton.vue";
import SignOutButton from "./SignOutButton.vue";

export default defineComponent({
  name: 'NavBar',
  components: {
    SignInButton,
    SignOutButton
  },
  data() {
    return {
      name: '',
      isAuthenticated: useIsAuthenticated(),
      menus: [
        // {
        //   title: 'Home',
        //   url: '/'
        // },
        {
          title: 'Article',
          url: '/article'
        }
      ]
    }
  }
});
