
import { defineComponent } from 'vue';
import NavBar from "./components/NavBar.vue";
import SvgIcon from './components/SvgIcon.vue';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    SvgIcon
  }
});
