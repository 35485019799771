import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-block text-right px-3 bg-purple" }
const _hoisted_2 = { class: "italic text-base leading-7" }
const _hoisted_3 = { class: "text-xs leading-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.username), 1)
  ]))
}