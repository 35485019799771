
import { defineComponent } from 'vue';
import { useMsal } from '../composition-api/useMsal';

export default defineComponent({
  name: 'WelcomeName',
  components: {},
  data() {
    return {
      name: '',
      username: ''
    }
  },
  mounted() {
    const { accounts } = useMsal();
    if (accounts.value.length > 0) {
      const name = accounts.value[0].name;
      if (name) {
        this.name = name;
      }
      const username = accounts.value[0].username;
      if (username) {
        this.username = username;
      }
    }
  }
});
